/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import { Analytics } from "@mui/icons-material";
import EnrichmentIcon from "@mui/icons-material/Assignment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import StudentRegistrationIcon from "@mui/icons-material/PersonAddAlt";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQueryGetProfileForValidateEnrichment } from "../../../api/enrichment-track";
import { useQueryListNavbar } from "../../../api/user-management";
import { selectAuthUser } from "../../../store/auth/selector";
import { selectProfilePrivileges } from "../../../store/profile/selector";
import { layoutPrivateStyle } from "../../../styles/layout/private-routes";
import { AdminMenuProps } from "../../../types/admin-menu";
import { NavbarResponse, RoleListResponseEdit } from "../../../types/user-role";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";

export function SideMenuAdmin({
  adminMenu,
  setAdminMenu,
  activeMenu,
  setActiveMenu,
}: AdminMenuProps) {
  const listPrivileges = useSelector(selectProfilePrivileges);
  const profile = useSelector(selectAuthUser);
  const Navigate = useNavigate();

  const [openCollapse, setOpenCollapse] = useState<string[]>([]);
  const isSmall = useMediaQuery("(min-width: 600px)");
  const [modulOpen, setModulOpen] = useState(adminMenu);
  const [validateForEnrichment, setValidateForEnrichment] = useState(false);
  const [listRole, setListRole] = useState<NavbarResponse[]>([]);

  const userData = JSON.parse(localStorage.getItem("userData") ?? "{}");

  useEffect(() => {
    if (
      window.location.pathname === "/manage-presets/define-preset" ||
      window.location.pathname === "/dashboard/editor"
    ) {
      setModulOpen(true);
      setAdminMenu?.(true);
    }
    setActiveMenu?.(window.location.pathname);
  }, [window.location.pathname]);

  const sideMenuModel = {
    initial1: {
      height: "100%",
      width: "240px",
    },
    initial2: {
      height: "100%",
      width: "52px",
    },
    animate1: { width: adminMenu ? "52px" : "240px" },
    animate2: { width: adminMenu ? "240px" : "52px" },
  };

  const variantSideMenu = isSmall
    ? {
        initial: sideMenuModel.initial1,
        animate: sideMenuModel.animate1,
      }
    : !modulOpen
    ? {
        initial: sideMenuModel.initial2,
        animate: sideMenuModel.animate2,
      }
    : {
        initial: sideMenuModel.initial1,
        animate: sideMenuModel.animate1,
      };

  const cardModel = {
    initial1: {
      width: "220px",
      height: "140px",
      margin: "10px 10px 20px 10px",
      overflow: "hidden",
    },
    initial2: {
      width: "40px",
      height: "140px",
      margin: "10px 6px 20px 6px",
      overflow: "hidden",
    },
    animate1: {
      width: adminMenu ? "40px" : "220px",
      margin: adminMenu ? "10px 6px 20px 6px" : "10px 10px 20px 10px",
    },
    animate2: {
      width: adminMenu ? "220px" : "40px",
      margin: adminMenu ? "10px 10px 20px 10px" : "10px 6px 20px 6px",
    },
  };

  const cards = isSmall
    ? {
        initial: cardModel.initial1,
        animate: cardModel.animate1,
      }
    : !modulOpen
    ? {
        initial: cardModel.initial2,
        animate: cardModel.animate2,
      }
    : {
        initial: cardModel.initial1,
        animate: cardModel.animate1,
      };

  const sideMenuTitleModul = isSmall
    ? adminMenu
      ? { display: "none" }
      : layoutPrivateStyle.sideMenuTitle
    : modulOpen
    ? !adminMenu
      ? layoutPrivateStyle.sideMenuTitle
      : { display: "none" }
    : adminMenu
    ? layoutPrivateStyle.sideMenuTitle
    : { display: "none" };

  const sideMenuIconCollapseModul = isSmall
    ? adminMenu
      ? { display: "none" }
      : layoutPrivateStyle.sideMenuIconCollapse
    : modulOpen
    ? !adminMenu
      ? layoutPrivateStyle.sideMenuIconCollapse
      : { display: "none" }
    : adminMenu
    ? layoutPrivateStyle.sideMenuIconCollapse
    : { display: "none" };

  const sideMenuCollapseModul = isSmall
    ? adminMenu
      ? { display: "none" }
      : { display: "block" }
    : modulOpen
    ? !adminMenu
      ? { display: "block" }
      : { display: "none" }
    : adminMenu
    ? { display: "block" }
    : { display: "none" };

  const sideMenuIconButtonModul = isSmall
    ? adminMenu
      ? layoutPrivateStyle.sideMenuPrivateIconButton
      : layoutPrivateStyle.sideMenuPrivateIconButtonActive
    : modulOpen
    ? !adminMenu
      ? layoutPrivateStyle.sideMenuPrivateIconButtonActive
      : layoutPrivateStyle.sideMenuPrivateIconButton
    : adminMenu
    ? layoutPrivateStyle.sideMenuPrivateIconButtonActive
    : layoutPrivateStyle.sideMenuPrivateIconButton;

  const handleOpen = () => {
    if (isSmall) setModulOpen(!modulOpen);
    setAdminMenu?.(!adminMenu);
  };

  const getProfile = useQueryGetProfileForValidateEnrichment({
    query: {
      binusianid: profile.binusianId,
    },
    options: {
      enabled: validateForEnrichment,
      onSuccess: (res) => {
        setValidateForEnrichment(false);
        res.data.action === "Submit"
          ? Navigate("/enrichment/update-profile")
          : res.data.action === "Edit"
          ? Navigate("/enrichment/choose-enrichment-track")
          : Navigate("/dashboard");
      },
      onError: () => {
        setValidateForEnrichment(false);
        Navigate("/dashboard");
      },
    },
  });

  const getNavbarList = useQueryListNavbar({
    query: {
      roleId: userData.roleId,
    },
    options: {
      onSuccess: (res) => {
        setListRole(res || []);
      },
      onError: () => {
        alert("Error");
      },
    },
  });

  const handleClick = (link) => {
    if (link === "/enrichment/choose-enrichment-track") {
      setValidateForEnrichment(true);
    } else {
      setValidateForEnrichment(false);
    }

    setActiveMenu?.(link);
    Navigate(link);
  };

  const handleCollapse = (e) => {
    if (openCollapse?.find((collapse) => collapse === e))
      setOpenCollapse?.(openCollapse?.filter((collapse) => collapse !== e));
    else setOpenCollapse((prev) => [...prev, e]);
  };

  const findCollapse = (e) => {
    if (openCollapse?.find((collapse) => collapse === e)) return true;
    return false;
  };

  const linkLists = [
    {
      name: "Dashboard",
      icon: <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/dashboard",
      key: "",
      collapseList: [],
    },
    {
      name: "User Management",
      icon: <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/",
      key: "",
      collapseList: [
        {
          name: "User Role",
          link: "/user-management/user-role",
        },
        {
          name: "User Mapping",
          link: "/user-management/user-mapping",
        },
      ],
    },
    {
      name: "Student Registration",
      icon: <StudentRegistrationIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/",
      key: "",
      collapseList: [
        {
          name: "Student Period Mapping",
          link: "/student-registration/student-period-mapping",
        },
        {
          name: "Student Track Registration",
          link: "/student-registration/student-track-registration",
        },
        {
          name: "Change Student Track",
          link: "/student-registration/change-student-track",
        },
        {
          name: "Drop Student Track",
          link: "/student-registration/drop-student-track",
        },
      ],
    },
    {
      name: "Enrichment",
      icon: <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/",
      key: "",
      collapseList: [
        {
          name: "Update Profile",
          link: "/enrichment/update-profile",
        },
        {
          name: "Enrichment Track",
          link: "/enrichment/choose-enrichment-track",
        },
      ],
    },
    {
      name: "Enrichment Approval",
      icon: <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/enrichment-approval",
      key: "",
      collapseList: [],
    },
    {
      name: "Setup Enrichment Program",
      icon: <Analytics sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/",
      key: "",
      collapseList: [
        {
          name: "Transfer Assesment Component",
          link: "/setup-enrichment-program/transfer-asessment-component",
        },
        {
          name: "Asessment Mapping",
          link: "/setup-enrichment-program/asessment-mapping",
        },
        {
          name: "Evaluation Component",
          link: "/setup-enrichment-program/evaluation-component",
        },
        {
          name: "Evaluation Indicator",
          link: "/setup-enrichment-program/evaluation-indicator",
        },
        {
          name: "Evaluation Mapping",
          link: "/setup-enrichment-program/evaluation-mapping",
        },
        {
          name: "Set Due Date",
          link: "/setup-enrichment-program/set-due-date",
        },
        {
          name: "Send Approval Key",
          link: "/setup-enrichment-program/send-approval-key",
        },
        {
          name: "Generate Grade Fall",
          link: "/setup-enrichment-program/generate-grade-fall",
        },
        {
          name: "Transfer Grade To BCS",
          link: "/setup-enrichment-program/transfer-grade-to-bcs",
        },
      ],
    },
    {
      name: "Assesment & Evaluation",
      icon: <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/",
      key: "",
      collapseList: [
        {
          name: "Assesment",
          link: "/assesment-evaluation/assesment",
        },
        {
          name: "Evaluation",
          link: "/assesment-evaluation/evaluation",
        },
      ],
    },
    {
      name: "Assesment & Evaluation Approval",
      icon: <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/",
      key: "",
      collapseList: [
        {
          name: "Assesment",
          link: "/assesment-evaluation-approval/assesment",
        },
        {
          name: "Evaluation",
          link: "/assesment-evaluation-approval/evaluation",
        },
      ],
    },
    {
      name: "Streaming",
      icon: <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />,
      link: "/",
      key: "",
      collapseList: [
        {
          name: "Registration",
          link: "/streaming/registration",
        },
        {
          name: "Result",
          link: "/streaming/result",
        },
      ],
    },
  ];

  // if (userData.roleId =="1" ){
  //   linkLists = [
  //     {
  //       name: "Dashboard",
  //       icon: <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/dashboard",
  //       key: "",
  //       collapseList: [],
  //     },
  //     {
  //       name: "User Management",
  //       icon: <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "User Role",
  //           link: "/user-management/user-role",
  //         },
  //         {
  //           name: "User Mapping",
  //           link: "/user-management/user-mapping",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Enrichment",
  //       icon: <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "Update Profile",
  //           link: "/enrichment/update-profile",
  //         },
  //       ],
  //     },

  //   ];
  // }else if (userData.roleId =="2" ){
  //   linkLists = [
  //     {
  //       name: "Dashboard",
  //       icon: <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/dashboard",
  //       key: "",
  //       collapseList: [],
  //     },
  //     {
  //       name: "Enrichment",
  //       icon: <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "Update Profile",
  //           link: "/enrichment/update-profile",
  //         },
  //         {
  //           name: "Enrichment Track",
  //           link: "/enrichment/choose-enrichment-track",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Streaming",
  //       icon: <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "Registration",
  //           link: "/streaming/registration",
  //         },
  //         {
  //           name: "Result",
  //           link: "/streaming/result",
  //         },
  //       ],
  //     },
  //   ]
  // }else if (userData.roleId =="3" ){
  //   linkLists = [
  //     {
  //       name: "Dashboard",
  //       icon: <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/dashboard",
  //       key: "",
  //       collapseList: [],
  //     },
  //     {
  //       name: "Student Registration",
  //       icon: <StudentRegistrationIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "Student Period Mapping",
  //           link: "/student-registration/student-period-mapping",
  //         },
  //         {
  //           name: "Student Track Registration",
  //           link: "/student-registration/student-track-registration",
  //         },
  //         {
  //           name: "Change Student Track",
  //           link: "/student-registration/change-student-track",
  //         },
  //         {
  //           name: "Drop Student Track",
  //           link: "/student-registration/drop-student-track",
  //         }
  //       ],
  //     },
  //     {
  //       name: "Enrichment",
  //       icon: <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "Update Profile",
  //           link: "/enrichment/update-profile",
  //         },
  //         {
  //           name: "Enrichment Track",
  //           link: "/enrichment/choose-enrichment-track",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Enrichment Approval",
  //       icon: <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/enrichment-approval",
  //       key: "",
  //       collapseList: [],
  //     },
  //     {
  //       name: "Setup Enrichment Program",
  //       icon: <Analytics sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "Set Due Date",
  //           link: "/setup-enrichment-program/set-due-date",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Streaming",
  //       icon: <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />,
  //       link: "/",
  //       key: "",
  //       collapseList: [
  //         {
  //           name: "Registration",
  //           link: "/streaming/registration",
  //         },
  //         {
  //           name: "Result",
  //           link: "/streaming/result",
  //         },
  //       ],
  //     },
  //   ]
  // }

  return (
    <motion.div
      key="animate-sidemenu"
      initial={variantSideMenu.initial}
      animate={variantSideMenu.animate}
    >
      <Box sx={layoutPrivateStyle.sideMenuPaperBox}>
        {modulOpen !== true && (
          <Box sx={layoutPrivateStyle.sidebarCard}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex" }}>
                <Box
                  component="img"
                  src="/assets/icon/icon-face-shape.svg"
                  // sx={layoutPrivateStyle.headerIcon}
                />
                <Typography
                  sx={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {userData.roleDesc || "Role"}
                </Typography>
              </Box>
              <Button variant="contained" sx={layoutPrivateStyle.changeButton}>
                Change
              </Button>
            </Box>

            <Box sx={{ marginTop: "30px" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                {userData.name || "Name"}
              </Typography>

              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Semester {userData.semester || "-"}
              </Typography>

              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                {userData.termDesc || "Periode"}
              </Typography>
            </Box>
          </Box>
        )}

        <IconButton
          onClick={handleOpen}
          color="primary"
          sx={sideMenuIconButtonModul}
        >
          {isSmall ? (
            !adminMenu ? (
              <KeyboardDoubleArrowLeftIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            ) : (
              <KeyboardDoubleArrowRightIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            )
          ) : modulOpen ? (
            !adminMenu ? (
              <KeyboardDoubleArrowLeftIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            ) : (
              <KeyboardDoubleArrowRightIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            )
          ) : adminMenu ? (
            <KeyboardDoubleArrowLeftIcon
              sx={layoutPrivateStyle.sideMenuPrivateIcon}
            />
          ) : (
            <KeyboardDoubleArrowRightIcon
              sx={layoutPrivateStyle.sideMenuPrivateIcon}
            />
          )}
        </IconButton>
        {/* <motion.div key="animate-userinfocard" initial={cards.initial} animate={cards.animate}>
          <UserInfoCard adminMenu={adminMenu} modulOpen={modulOpen} />
        </motion.div> */}
        <Box
          sx={{
            height: "1px",
            background: "#008DC7",
            width: "100%",
            marginBottom: "16px",
          }}
        />
        <List sx={{ width: "100%", padding: "0" }}>
          {listRole.map((list) => (
            <Stack
              key={list.name}
              sx={{
                display:
                  list.key !== "" && !listPrivileges?.includes(`${list?.key}`)
                    ? "none"
                    : undefined,
              }}
            >
              <ListItemButton
                key={list.name}
                sx={
                  activeMenu?.includes(`${list.link}`) && list.link !== "/"
                    ? layoutPrivateStyle.sideMenuLinkActive
                    : findCollapse(list.name)
                    ? layoutPrivateStyle.sideMenuLinkCollapse
                    : layoutPrivateStyle.sideMenuLink
                }
                onClick={
                  list.collapseList.length === 0
                    ? () => handleClick(list.link)
                    : () => handleCollapse(list.name)
                }
              >
                <Stack direction="row" gap="4px" alignItems="center">
                  {list.name === "Dashboard" && (
                    <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "User Management" && (
                    <AssignmentIndIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Student Registration" && (
                    <StudentRegistrationIcon
                      sx={layoutPrivateStyle.sideMenuIcon}
                    />
                  )}

                  {list.name === "Study Plan" && (
                    <CalendarMonthIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Setup Due Date" && (
                    <DateRangeIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Score Entry Evaluation" && (
                    <AssignmentIndIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Enrichment" && (
                    <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Enrichment Approval" && (
                    <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Setup Enrichment Program" && (
                    <Analytics sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Assesment & Evaluation" && (
                    <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Assesment & Evaluation Approval" && (
                    <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}

                  {list.name === "Streaming" && (
                    <VideoLibraryIcon sx={layoutPrivateStyle.sideMenuIcon} />
                  )}
                  <Typography sx={sideMenuTitleModul}>{list.name}</Typography>
                </Stack>
                {findCollapse(list.name) ? (
                  <ExpandMore sx={sideMenuIconCollapseModul} />
                ) : (
                  list.collapseList.length > 0 && (
                    <KeyboardArrowLeftIcon sx={sideMenuIconCollapseModul} />
                  )
                )}
              </ListItemButton>
              {list.collapseList.length > 0 && (
                <Collapse
                  in={findCollapse(list.name)}
                  timeout="auto"
                  unmountOnExit
                  sx={sideMenuCollapseModul}
                >
                  <List component="div" disablePadding>
                    {list.collapseList?.map((collapse) => (
                      <ListItemButton
                        key={collapse.name}
                        sx={layoutPrivateStyle.sideMenuBoxCollapse}
                        onClick={() => handleClick(collapse.link)}
                      >
                        <Stack
                          sx={
                            activeMenu === collapse.link
                              ? layoutPrivateStyle.sideMenuBoxCollapseLinkActive
                              : layoutPrivateStyle.sideMenuBoxCollapseLink
                          }
                        >
                          {/* {collapse.icon} */}
                          <Typography
                            sx={
                              !adminMenu
                                ? layoutPrivateStyle.sideMenuTitle
                                : layoutPrivateStyle.sideMenuTitleMobile
                            }
                          >
                            {collapse.name}
                          </Typography>
                        </Stack>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </Stack>
          ))}
        </List>
      </Box>
    </motion.div>
  );
}
