import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import DetailIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import AddOutlined from "@mui/icons-material/AddOutlined";

import { GridColDef, GridCellParams } from "@mui/x-data-grid";

import { useEffect, useState } from "react";

import { CustomDataGrid } from "../../components/common/datagrid";
import {
  useMutationGetStudentPeriod,
  useMutationSubmitSetMappingAditional,
  useMutationSubmitSetMappingBulk,
  useMutationResetMapping,
  useQueryGetDetailStudentPeriod,
  useQueryGetDropDownAcadCareer,
  useQueryGetDropDownCheckFor,
  useQueryGetTermDropDown,
  useQueryGetTermPeriodDropDown,
  useQueryGetTermRegularDropDown,
  useQueryGetTrackDropDown,
} from "../../api/student-registration";
import {
  AcadCareerData,
  CheckForData,
  GetStudentPeriodData,
  TermDataList,
  TrackData,
} from "../../types/student-registration";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { DropdownResponseData, AcadOrgDropdownResponseData } from "../../types/dropdown";
import {
  useQueryAcadGroup,
  useQueryAcadOrg,
  useQueryAcadProg,
  useQueryAcadPlan,
  useQueryDropDownAdmitTerm,
  useQueryListAcadCareerDropDown,
  useQueryListCampusLocationDropDown,
  useQueryListInstitutionDropDown,
} from "../../api/dropdown";
import useModal from "../../hooks/use-modal";

export function StudentPeriodMapping() {
  const { showModal } = useModal();

  const dataStudentMapping = [
    {
      studentMappingId: 1,
      studentMappingName: "Bulk Mapping",
    },
    {
      studentMappingId: 2,
      studentMappingName: "Additional Mapping",
    },
  ];

  const dataEnrichmentTrack = [
    {
      enrichmentTrackId: 1,
      enrichmentTrackName: "enrichmentTrackName1",
    },
    {
      enrichmentTrackId: 2,
      enrichmentTrackName: "enrichmentTrackName2",
    },
    {
      enrichmentTrackId: 3,
      enrichmentTrackName: "enrichmentTrackName3",
    },
    {
      enrichmentTrackId: 4,
      enrichmentTrackName: "enrichmentTrackName4",
    },
    {
      enrichmentTrackId: 5,
      enrichmentTrackName: "enrichmentTrackName5",
    },
  ];

  const StagedStudentDetailList = [
    {
      ID: 1,
      BinusianID: "BN123451",
      Name: "Corey Rhiel Madsen",
      N_ENRICHMENT_TRACK: "FE",
      DESCR: "Free Electives",
      PERIOD_1: "2321",
      PERIOD_2: "2321",
      PERIOD_REGULAR: "2321",
    },
    {
      ID: 2,
      BinusianID: "BN123451",
      Name: "Corey Rhiel Madsen",
      N_ENRICHMENT_TRACK: "MP",
      DESCR: "Minor Technopreneurship",
      PERIOD_1: "2321",
      PERIOD_2: "2321",
      PERIOD_REGULAR: "2321",
    },
    {
      ID: 3,
      BinusianID: "BN123451",
      Name: "Corey Rhiel Madsen",
      N_ENRICHMENT_TRACK: "SIS",
      DESCR: "Specific Independent Study",
      PERIOD_1: "2321",
      PERIOD_2: "2321",
      PERIOD_REGULAR: "2321",
    },
  ];

  const [academicCareer, setAcademicCareer] = useState("");
  const [studentName, setStudentName] = useState("");
  const [checkFor, setCheckFor] = useState("1");
  const [studentMapping, setStudentMapping] = useState("");
  const [emplid, setEmplid] = useState("");
  const [studentId, setStudentId] = useState("");
  const [binusianId, setBinusianId] = useState("");
  const [emplidMultiple, setEmplidMultiple] = useState<
    { id: any; value: string }[]
  >([]);
  const [idEmplidMultiple, setIdEmplidMultiple] = useState(0);
  const [enrichmentTrack, setEnrichmentTrack] = useState<string[]>([]);
  const [enrichmentTrackSecondPriority, setEnrichmentTrackSecondPriority] =
    useState<string[]>(["MA", "FE", "MP"]);
  const [period1, setPeriod1] = useState("");
  const [period2, setPeriod2] = useState("");
  const [regularPeriod, setRegularPeriod] = useState("");
  const [listTrack, setListTrack] = useState<TrackData[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [openMappingModal, setOpenMappingModal] = useState(false);
  const [openStagedStudentDetail, setOpenStagedStudentDetail] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllSecond, setSelectAllSecond] = useState(false);

  // Dropdown Data
  const [listTerm, setListTerm] = useState<TermDataList[]>([]);
  const [dataTermPeriod, setDataTermPeriod] = useState<TermDataList[]>([]);
  const [dataTermReguler, setDataTermReguler] = useState<TermDataList[]>([]);
  const [listAdmitTerm, setListAdmitTerm] = useState<TermDataList[]>([]);
  const [dropInstitution, setDropInstitution] = useState<
    DropdownResponseData[]
  >([]);
  const [dropCampus, setDropCampus] = useState<DropdownResponseData[]>([]);
  const [dropAcadGroup, setDropAcadGroup] = useState<DropdownResponseData[]>(
    []
  );
  const [dropAcadProg, setDropAcadProg] = useState<DropdownResponseData[]>([]);
  const [dropAcadPlan, setDropAcadPlan] = useState<DropdownResponseData[]>([]);

  const [dataCheckFor, setDataCheckFor] = useState<CheckForData[]>(
    [] as CheckForData[]
  );
  const [dataAcadCareer, setDataAcadCareer] = useState<AcadCareerData[]>(
    [] as AcadCareerData[]
  );
  const [dataAcadOrg, setDataAcadOrg] = useState<AcadOrgDropdownResponseData[]>([] as AcadOrgDropdownResponseData[]);

  const [dataCareerDetail, setDataCareerDetail] = useState<any>("");
  const [dataPeriodMapping, setDataPeriodMapping] = useState<
    GetStudentPeriodData[]
  >([] as GetStudentPeriodData[]);

  // Value Drop Down
  const [period, setPeriod] = useState<string | null>(null);
  const [admitTerm, setAdmitTerm] = useState<string | null>(null);
  const [institutionValue, setInstitutionValue] = useState("BNS01");
  const [campusValue, setCampusValue] = useState("");
  const [acadCareerValue, setAcadCareerValue] = useState("");
  const [acadGroupValue, setAcadGroupValue] = useState("");
  const [acadOrgValue, setAcadOrgValue] = useState("");
  const [acadProgValue, setAcadProgValue] = useState("");
  const [acadPlanValue, setAcadPlanValue] = useState("");
  const [idDetailAcadCareer, setIdDetailAcadCareer] = useState("");

  const submitAction = (id: string = "") => {
    setIdDetailAcadCareer(id);
    setOpenStagedStudentDetail(true);
  };

  const columnsData: GridColDef[] = [
    {
      field: "id",
      headerName: "NO",
      flex: 0.5,
      maxWidth: 100,
      hideable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {    
        const pageOffset = paginationModel.page * paginationModel.pageSize;
        const rowIndex = dataPeriodMapping.findIndex(row => row.id === params.id);
        return pageOffset + (rowIndex + 1);
      },
    },
    {
      field: "acadCareer",
      headerName: "ACAD CAREER",
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: "binusId",
      headerName: "BINUSIAN ID",
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: "externalSystemId",
      headerName: "STUDENT ID",
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: "name",
      headerName: "STUDENT NAME",
      flex: 1,
      minWidth: 200,
      hideable: false,
    },
    {
      field: "Action",
      headerName: "ACTION",
      width: 100,
      align: "center",
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <IconButton
          aria-label="detail"
          onClick={() => submitAction(params.id.toString())}
        >
          <DetailIcon />
        </IconButton>
      ),
    },
  ];

  const conditionColumnsStagedStudentDetail = (checkForValue) => {
    const columnsStagedStudentDetail: GridColDef[] = [
      {
        field: "BinusianID",
        headerName: "BinusianID",
        flex: 0.5,
        minWidth: 100,
        hideable: false,
      },
      {
        field: "Name",
        headerName: "Name",
        flex: 1,
        minWidth: 200,
        hideable: false,
      },
      {
        field: "N_ENRICHMENT_TRACK",
        headerName: "N_ENRICHMENT_TRACK",
        flex: 0.5,
        minWidth: 100,
        hideable: false,
      },
      {
        field: "DESCR",
        headerName: "DESCR",
        flex: 0.5,
        minWidth: 100,
        hideable: false,
      },
      {
        field: "PERIOD_1",
        headerName: "PERIOD 1",
        flex: 0.5,
        minWidth: 100,
        hideable: false,
      },
      {
        field: "PERIOD_2",
        headerName: "PERIOD 2",
        flex: 0.5,
        minWidth: 100,
        hideable: false,
      },
      {
        field: "PERIOD_REGULAR",
        headerName: "PERIOD REGULAR",
        flex: 1,
        minWidth: 200,
        hideable: false,
      },
      // {
      //   field: 'Action',
      //   headerName: 'Action',
      //   width: 100,
      //   align: 'center',
      //   hideable: false,
      //   renderCell: (params: GridCellParams) => (
      //     <IconButton aria-label="delete">
      //       <DeleteIcon />
      //     </IconButton>
      //   )
      // }
    ];

    if (checkForValue === "Staged Student") {
      columnsStagedStudentDetail.push({
        field: "Action",
        headerName: "Action",
        width: 100,
        align: "center",
        hideable: false,
        renderCell: (params: GridCellParams) => (
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        ),
      });
    }

    return columnsStagedStudentDetail;
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value.includes("all")) {
      // Select all items
      if (selectAll) {
        // Deselect all if already selected
        setEnrichmentTrack([]);
      } else {
        // Select all items
        setEnrichmentTrack(listTrack.map((item) => item.Code));
      }
    } else {
      // Normal selection logic
      setEnrichmentTrack(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleChangePriority2 = (e) => {
    const {
      target: { value },
    } = e;
    if (value.includes("all")) {
      // Select all items
      if (selectAllSecond) {
        // Deselect all if already selected
        setEnrichmentTrackSecondPriority([]);
      } else {
        // Select all items
        setEnrichmentTrackSecondPriority(listTrack.map((item) => item.Code));
      }
    } else {
      // Normal selection logic
      setEnrichmentTrackSecondPriority(
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleAddEmplid = () => {
    if (emplid.trim()) {
      const newEmplid = { id: idEmplidMultiple, value: emplid.trim() };
      setEmplidMultiple([...emplidMultiple, newEmplid]);
      setEmplid("");
      setIdEmplidMultiple(idEmplidMultiple + 1);
    }
  };

  const handleDeleteEmplid = (id) => {
    setEmplidMultiple(emplidMultiple.filter((data) => data.id !== id));
  };

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDropInstitution(res.data || []);
        if (res.data && res.data.length > 0) {
          setInstitutionValue("BNS01");
        }
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryGetDropDownAcadCareer({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataAcadCareer(res.data || []);
        if (res.data && res.data.length > 0) {
          setAcadCareerValue("OS1");
        }
      },
      onError: () => {},
    },
  });

  const getAcadOrg = useQueryAcadOrg({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataAcadOrg(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadProg = useQueryAcadProg({
    query: {
      acadOrg: acadOrgValue
    },
    options: {
      enabled: !!acadOrgValue,
      onSuccess: (res) => {
        setDropAcadProg(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadPlan = useQueryAcadPlan({
    query: {
      acadOrg: acadOrgValue,
      acadProg: acadProgValue
    },
    options: {
      enabled: !!acadOrgValue && !!acadProgValue,
      onSuccess: (res) => {
        setDropAcadPlan(res.data || []);
      },
      onError: () => {},
    },
  });

  const getCheckFor = useQueryGetDropDownCheckFor({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setDataCheckFor(res.data || []);
      },
      onError: () => {},
    },
  });

  const getTrack = useQueryGetTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setListTrack(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAdmitTerm = useQueryDropDownAdmitTerm({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setListAdmitTerm(res.data || []);
      },
      onError: () => {},
    },
  });

  const getDetailAcadCareer = useQueryGetDetailStudentPeriod({
    query: { id: idDetailAcadCareer },
    options: {
      enabled: !!idDetailAcadCareer,
      onSuccess: (res) => {
        console.log(res, "RESasdasd");
        setDataCareerDetail(res);
      },
      onError: () => {},
    },
  });

  const getTerm = useQueryGetTermDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setListTerm(res.data || []);
      },
      onError: () => {},
    },
  });

  const getTermPeriod = useQueryGetTermPeriodDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataTermPeriod(res.data || []);
      },
      onError: () => {},
    },
  });

  const getTermReguler = useQueryGetTermRegularDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataTermReguler(res.data || []);
      },
      onError: () => {},
    },
  });

  const getStudentPeriod = useMutationGetStudentPeriod({
    query: {
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page+1,
      orderBy: "id:desc",
    },
    options: {
      onSuccess: async (res: any) => {
        setDataPeriodMapping(res.data || []);
        setTotalItems(res?.paginationInfo.totalItems || 0);
      },
      onError: async (err) => {
        console.log(err);
      },
    },
  });

  const submitGetStudentPeriod = async () => {
    getStudentPeriod.mutate({
      institution: institutionValue,
      acadCareer: acadCareerValue,
      acadOrg: acadOrgValue,
      acadProg: acadProgValue,
      acadPlan: acadPlanValue,
      period,
      checkFor,
      studentName,
      binusianId: emplid,
      studentId,
      admitTerm,
    });
  };

  useEffect(() => {
    submitGetStudentPeriod();
  }, [paginationModel]); 

  const submitSetMappingBulk = useMutationSubmitSetMappingBulk({
    onSuccess: async (res: any) => {
      setOpenMappingModal(false);
      setStudentMapping("");
      setEmplid("");
      setEmplidMultiple([]);
      setSelectAll(false);
      setSelectAllSecond(false);
      setEnrichmentTrack([]);
      setEnrichmentTrackSecondPriority(["MA", "FE", "MP"]);
      setPeriod1("");
      setPeriod2("");
      setRegularPeriod("");
      showModal({
        title: "Success",
        message: `Submit Bulk Mapping Success`,
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk() {
            submitGetStudentPeriod();
          },
        },
      });
    },
    onError: async (err) => {
      const checkError = err?.response?.data.message;

      const errorMessage = checkError === "Error" 
      ? err?.response?.data.details 
      : err?.response?.data.message;

      setOpenMappingModal(false);
      showModal({
        title: "Failed",
        message: errorMessage || "Submit bulk mapping is failed.",
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const submitSetMappingAditional = useMutationSubmitSetMappingAditional({
    onSuccess: async (res: any) => {
      setOpenMappingModal(false);
      setStudentMapping("");
      setEmplid("");
      setEmplidMultiple([]);
      setSelectAll(false);
      setSelectAllSecond(false);
      setEnrichmentTrack([]);
      setEnrichmentTrackSecondPriority(["MA", "FE", "MP"]);
      setPeriod1("");
      setPeriod2("");
      setRegularPeriod("");
      showModal({
        title: "Success",
        message: `Submit Additional Mapping Success`,
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk() {
            submitGetStudentPeriod();
          },
        },
      });
    },
    onError: async (err) => {
      const checkError = err?.response?.data.message;

      const errorMessage = checkError === "Error" 
      ? err?.response?.data.details 
      : err?.response?.data.message;

      setOpenMappingModal(false);
      showModal({
        title: "Failed",
        message: errorMessage || "Submit additional mapping is failed.",
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const submitSetMapBulk = async () => {
    submitSetMappingBulk.mutate({
      isAdditional: false,
      firstTrack: `${enrichmentTrack.join(";")};`,
      secondTrack: `${enrichmentTrackSecondPriority.join(";")};`,
      period,
      firstPeriod: period1,
      secondPeriod: period2,
      regularPeriod,
    });
  };

  const submitSetAditional = async () => {
    submitSetMappingAditional.mutate({
      isAdditional: true,
      firstTrack: `${enrichmentTrack.join(";")};`,
      secondTrack: `${enrichmentTrackSecondPriority.join(";")};`,
      period,
      firstPeriod: period1,
      secondPeriod: period2,
      regularPeriod,
      binusianId: emplidMultiple.map((item) => ({ id: item.value })),
    });
  };

  const resetMapping = useMutationResetMapping({
    onSuccess: async (res) => {
      showModal({
        title: "Success",
        message: "Data successfully reset",
        options: {
          variant: "success",
          onOk() {
            submitGetStudentPeriod();
          },
        },
      });
    },
    onError: (err) => {
      const checkError = err?.response?.data.message;

      const errorMessage = checkError === "Error" 
      ? err?.response?.data.details 
      : err?.response?.data.message;

      showModal({
        title: "Failed",
        message: errorMessage || "Reset data is failed.",
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const handleConfirmationResetMapping = (data?: string) => {
    showModal({
      title: "Alert",
      message: `Are you sure you want to reset this data?`,
      options: {
        buttonTitle: "Ok",
        buttonTitle2: "Cancel",
        variant: "info",
        onOk() {
          resetMapping.mutate({
            institution: institutionValue,
            acadCareer: acadCareerValue,
            acadOrg: acadOrgValue,
            acadProg: acadProgValue,
            acadPlan: acadPlanValue,
            period,
            admitTerm,
            checkFor: parseInt(checkFor, 10),
            binusianId: emplid,
            studentId,
            studentName,
          });
        },
      },
    });
  };

  return (
    <>
      <Container maxWidth={false}>
        <Stack mt={2} spacing={2}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
              Student Registration
            </Link>
            <Typography fontSize={14} color="#0097DA">
              Student Period Mapping
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Typography variant="h5" gutterBottom mt={2}>
          Student Period Mapping
        </Typography>

        <Divider />

        <Box mt={2} mb={2}>
          <Grid container spacing={2}>
            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="institution-select-label">Institution</InputLabel>
                <Select
                  disabled
                  labelId="institution-select-label"
                  id="institution-select"
                  label="Institution"
                  value={institutionValue}
                  onChange={(e) => {
                    setInstitutionValue(e.target.value as string);
                  }}
                >
                  {dropInstitution.map((term) => (
                    <MenuItem value={term.Code}>{term.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="acadCareer-select-label">Academic Career</InputLabel>
                <Select
                  disabled
                  labelId="acadCareer-select-label"
                  id="acadCareer-select"
                  label="Academic Career"
                  value={acadCareerValue}
                  onChange={(e) => {
                    setAcadCareerValue(e.target.value as string);
                  }}
                >
                  {dataAcadCareer.map((term) => (
                    <MenuItem value={term.Code}>{term.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="acadOrganization-select-label">Academic Organization</InputLabel>
                <Select
                  labelId="acadOrganization-select-label"
                  id="acadOrganization-select"
                  label="Academic Organization"
                  value={acadOrgValue}
                  onChange={(e) => {
                    setAcadOrgValue(e.target.value as string);
                  }}
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                  {dataAcadOrg.map((item) => (
                    <MenuItem value={item.Code}>{item.Code} - {item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="acadProgram-select-label">Academic Program</InputLabel>
                <Select
                  labelId="acadProgram-select-label"
                  id="acadProgram-select"
                  label="Academic Program"
                  value={acadProgValue}
                  onChange={(e) => {
                    setAcadProgValue(e.target.value as string);
                  }}
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                  {dropAcadProg.map((item) => (
                    <MenuItem value={item.Code}>{item.Code} - {item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="acadPlan-select-label">Academic Plan</InputLabel>
                <Select
                  labelId="acadPlan-select-label"
                  id="acadPlan-select"
                  label="Academic Plan"
                  value={acadPlanValue}
                  onChange={(e) => {
                    setAcadPlanValue(e.target.value as string);
                  }}
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                  {dropAcadPlan.map((item) => (
                    <MenuItem value={item.Code}>{item.Code} - {item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <Autocomplete
                fullWidth
                size="small"
                value={listTerm.find(term => term.Code === period) || null}
                onChange={(event, newValue) => {
                  setPeriod(newValue ? newValue.Code : null);
                }}
                // inputValue={inputValue}
                // onInputChange={(event, newInputValue) => {
                //   setInputValue(newInputValue);
                // }}
                id="period-select"
                options={listTerm}
                getOptionLabel={(option) => option.Name}
                renderInput={(params) => <TextField {...params} label="Enrichment Term" />}
              />
            </Grid>

            <Grid mb={2} xs={6} item>
              <Autocomplete
                fullWidth
                size="small"
                value={listAdmitTerm.find(term => term.Code === admitTerm) || null}
                onChange={(event, newValue) => {
                  setAdmitTerm(newValue ? newValue.Code : null);
                }}
                id="admitTerm-select"
                options={listAdmitTerm}
                getOptionLabel={(option) => option.Name}
                renderInput={(params) => <TextField {...params} label="Admit Term" />}
              />
            </Grid>

            <Grid mb={2} xs={12} item>
              <Divider />
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="CheckFor-select-label">Check For</InputLabel>
                <Select
                  labelId="CheckFor-select-label"
                  id="CheckFor-select"
                  label="Check For"
                  value={checkFor}
                  onChange={(e) => {
                    setCheckFor(e.target.value as string);
                  }}
                >
                  {dataCheckFor.map((item) => (
                    <MenuItem value={item.Id}>{item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField 
                fullWidth
                size="small" 
                id="binusianId"
                label="Binusian ID" 
                variant="outlined"
                value={emplid}
                onChange={(e) => setEmplid(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField 
                fullWidth
                size="small" 
                id="studentId"
                label="Student ID" 
                variant="outlined"
                value={studentId}
                onChange={(e) => setStudentId(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField 
                fullWidth
                size="small" 
                id="studentName"
                label="Student Name" 
                variant="outlined"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2} mb={2} justifyContent="space-between">
          {checkFor === "1" && (
            <Grid item xs={8}>
              <Button onClick={() => {
                handleConfirmationResetMapping(); 
              }} 
                variant="contained" 
                color="warning"
              >
                Reset Mapping
              </Button>
            </Grid>
          )}

          <Grid item xs>
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              {checkFor === "1" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenMappingModal(true)}
                >
                  Set Mapping
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => submitGetStudentPeriod()}
              >
                Search
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        <Paper elevation={3}>
          <CustomDataGrid
            columnsData={columnsData}
            rowsData={dataPeriodMapping}
            rowCount={totalItems}
            getRowId={(row) => row.id}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
          />
        </Paper>
      </Container>
      <Dialog
        open={openMappingModal}
        onClose={() => {
          setOpenMappingModal(false);
          setStudentMapping("");
          setEmplid("");
          setEmplidMultiple([]);
          setSelectAll(false);
          setSelectAllSecond(false);
          setEnrichmentTrack([]);
          setEnrichmentTrackSecondPriority(["MA", "FE", "MP"]);
          setPeriod1("");
          setPeriod2("");
          setRegularPeriod("");
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Set Mapping
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenMappingModal(false);
            setStudentMapping("");
            setEmplid("");
            setEmplidMultiple([]);
            setSelectAll(false);
            setSelectAllSecond(false);
            setEnrichmentTrack([]);
            setEnrichmentTrackSecondPriority(["MA", "FE", "MP"]);
            setPeriod1("");
            setPeriod2("");
            setRegularPeriod("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <InfoIcon fontSize="small" color="warning" />
              <Typography
                sx={{ fontSize: "12px", fontWeight: "700", color: "#0097DA" }}
              >
                This form is used to map the track and period for all eligible
                students
              </Typography>
            </Stack>
            <FormControl fullWidth size="small">
              <InputLabel id="setStudentMapping-select-label">
                Set Student Mapping
              </InputLabel>
              <Select
                labelId="setStudentMapping-select-label"
                id="setStudentMapping-select"
                value={studentMapping}
                label="Set Student Mapping"
                onChange={(e) => setStudentMapping(e.target.value)}
              >
                {dataStudentMapping.map((item) => (
                  <MenuItem
                    key={item.studentMappingId}
                    value={item.studentMappingName}
                  >
                    {item.studentMappingName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {studentMapping === "Additional Mapping" && (
              <>
                <Grid container>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      id="emplid"
                      label="Student ID"
                      variant="outlined"
                      size="small"
                      value={emplid}
                      onChange={(event) => {
                        setEmplid(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => handleAddEmplid()}>
                      <AddOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} variant="outlined">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>NO</TableCell>
                            <TableCell>Student ID</TableCell>
                            <TableCell>{}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {emplidMultiple.map((row, index) => (
                            <TableRow key={row.id}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row.value}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleDeleteEmplid(row.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </>
            )}
            <FormControl fullWidth size="small">
              <InputLabel id="chooseEnrichmentTrack-multiple-chip-label">
                Choose Track For Priority 1
              </InputLabel>
              <Select
                labelId="chooseEnrichmentTrack-multiple-chip-label"
                id="chooseEnrichmentTrack-multiple-chip"
                multiple
                value={enrichmentTrack}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    id="chooseEnrichmentTrack-multiple-chip"
                    label="Choose Track For Priority 1"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem
                  key="all"
                  value="all"
                  onClick={() => setSelectAll(!selectAll)}
                >
                  <Checkbox checked={selectAll} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {listTrack.map((item) => (
                  <MenuItem key={item.Id} value={item.Code}>
                    <Checkbox checked={enrichmentTrack.includes(item.Code)} />
                    <ListItemText primary={item.Name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel id="chooseEnrichmentTrack-multiple-chip-label">
                Choose Track For Priority 2
              </InputLabel>
              <Select
                labelId="chooseEnrichmentTrack-multiple-chip-label"
                id="chooseEnrichmentTrack-multiple-chip"
                multiple
                value={enrichmentTrackSecondPriority}
                onChange={handleChangePriority2}
                input={
                  <OutlinedInput
                    id="chooseEnrichmentTrack-multiple-chip"
                    label="Choose Track For Priority 2"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem
                  key="all"
                  value="all"
                  onClick={() => setSelectAllSecond(!selectAllSecond)}
                >
                  <Checkbox checked={selectAllSecond} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {listTrack.map((item) => (
                  <MenuItem key={item.Id} value={item.Code}>
                    <Checkbox
                      checked={enrichmentTrackSecondPriority.includes(
                        item.Code
                      )}
                    />
                    <ListItemText primary={item.Name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider />
            <Typography sx={{ fontWeight: 700 }}>
              Mapping to Regular Period
            </Typography>
            <FormControl fullWidth size="small">
              <InputLabel id="period1-select-label">Period 1</InputLabel>
              <Select
                labelId="period1-select-label"
                id="period1-select"
                value={period1}
                label="Period 1"
                onChange={(e) => setPeriod1(e.target.value)}
              >
                {dataTermPeriod.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="period2-select-label">Period 2</InputLabel>
              <Select
                labelId="period2-select-label"
                id="period2-select"
                value={period2}
                label="Period 2"
                onChange={(e) => setPeriod2(e.target.value)}
              >
                {dataTermPeriod.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="regular-period-select-label">
                Regular Period
              </InputLabel>
              <Select
                labelId="regular-period-select-label"
                id="regular-period-select"
                value={regularPeriod}
                label="Regular Period"
                onChange={(e) => setRegularPeriod(e.target.value)}
              >
                {dataTermReguler.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              studentMapping === "Additional Mapping"
                ? submitSetAditional()
                : submitSetMapBulk();

                // submitGetStudentPeriod();
            }}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openStagedStudentDetail}
        onClose={() => {
          setOpenStagedStudentDetail(false);
        }}
        fullWidth
        maxWidth="lg"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Staged Student Detail
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenStagedStudentDetail(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={2} p={2}>
          <Grid item xs={6}>
            <Typography fontSize={12}>Binusian ID</Typography>
            <OutlinedInput
              disabled
              value={dataCareerDetail?.binusId}
              onChange={(e) => setStudentId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize={12}>Name</Typography>
            <OutlinedInput
              disabled
              value={dataCareerDetail?.name}
              onChange={(e) => setStudentId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize={12}>Period 1</Typography>
            <OutlinedInput
              disabled
              value={dataCareerDetail?.firstTermDesc}
              onChange={(e) => setStudentId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize={12}>Period 2</Typography>
            <OutlinedInput
              disabled
              value={dataCareerDetail?.secondTermDesc}
              onChange={(e) => setStudentId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize={12}>Period Regular</Typography>
            <OutlinedInput
              disabled
              value={dataCareerDetail?.regularTermDesc}
              onChange={(e) => setStudentId(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>

        <TableContainer sx={{ padding: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "1px solid #d3d3d3" }} align="left">
                  Track Priority 1
                </TableCell>
                <TableCell sx={{ border: "1px solid #d3d3d3" }} align="left">
                  Track Priority 2
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataCareerDetail?.listTrack?.map((row) => (
                <TableRow key={row.descSecond}>
                  <TableCell
                    sx={{ border: "1px solid #d3d3d3" }}
                    component="th"
                    scope="row"
                  >
                    {row.descFirst}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #d3d3d3" }}>
                    {row.descSecond}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </>
  );
}
