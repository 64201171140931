/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import { useQuery, useMutation } from '@tanstack/react-query';
import apiClient from '../config/api-client';
import { ApiEnrichmentApproval } from '../constants/ApiEnrichmentApproval';
import { EnrichmentApprovalResponse, UploadExcelList, BulkApprovalRequest } from '../types/enrichment-approval';
import { UseQueryOptions, UseMutationOptions } from '../types/react-query';

export const useQueryEnrichmentApprovalList = ({
  query,
  options,
}: {
  query: { search?: string, studentid?: string, pageSize?: number, pageNumber?: number, orderBy?: string, priority?: string};
  options?: UseQueryOptions<EnrichmentApprovalResponse>;
}) =>
  useQuery(
    ['enrichmentApprovalList', query.pageSize, query.pageNumber],
    () =>
      apiClient
        .get<unknown, { data: EnrichmentApprovalResponse }>(ApiEnrichmentApproval.enrichmentApprovalList(query.search, query.studentid, query.pageSize, query.pageNumber, query.orderBy, query.priority))
        .then((res) => res.data),
    options,
  );

export const useQueryExportExcel = ({
  query,
  options,
}: {
  query: { search: string, priority: string };
  options?: UseQueryOptions<any>;
}) =>
  useQuery(
    ['exportExcel'],
    () =>
      apiClient
        .get<any>(ApiEnrichmentApproval.exportExcel(query.search, query.priority), {
          responseType: 'blob'
        })
        .then((res) => res.data),
    options,
  );

export const useMutationUploadExcel = (options?: UseMutationOptions<UploadExcelList[]>) =>
  useMutation(
    (data: FormData) =>
      apiClient
        .post<unknown, { data: UploadExcelList[] }>(`${ApiEnrichmentApproval.uploadExcel}`, data)
        .then((res) => res.data),
    options,
  );

export const useMutationBulkApproval = (options?: UseMutationOptions) =>
  useMutation(
    (data: BulkApprovalRequest[]) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEnrichmentApproval.approveEnrichmentApproval}`, data)
        .then((res) => res.data),
    options,
  );