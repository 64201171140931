import {
  Container,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Stack,
  Select,
  TextField,
  Autocomplete,
  TextareaAutosize,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { GridColDef } from "@mui/x-data-grid";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  useQueryAcadGroup,
  useQueryAcadOrg,
  useQueryAcadProg,
  useQueryAcadPlan,
  useQueryDropDownAdmitTerm,
  useQueryListAcadCareerDropDown,
  useQueryListCampusLocationDropDown,
  useQueryListInstitutionDropDown,
} from "../../api/dropdown";
import {
  useMutationDownloadReport,
  useMutationDownloadTemplate,
  useMutationStudentBulk,
  useMutationSubmitStudentTrackRegistration,
  useMutationUploadRegistration,
  useQueryGetAssignPartnerDropDown,
  useQueryGetIndividual,
  useQueryGetTermDropDown,
  useQueryGetTrackDropDown,
  useQueryGetDetailStudentEnrichmentTrack,
} from "../../api/student-registration";
import { CustomDataGrid } from "../../components/common/datagrid";
import useModal from "../../hooks/use-modal";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { StudentRegCss } from "../../styles/page/StudentRegCss";
import { DropdownResponseData, AcadOrgDropdownResponseData } from "../../types/dropdown";
import {
  AssigmentPartnerData,
  GetIndividualData,
  StudentDataResponse,
  TermDataList,
  TrackData,
} from "../../types/student-registration";

// Function to format the date
const formatDate = (isoString) => {
  let formattedDate;

  if(isoString != null){
    const date = new Date(isoString);
    formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date);
  }
  
  return formattedDate;
};

// Function to format the time
const formatTime = (isoString) => {
  let formattedTime;

  if(isoString != null){
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',      // Correct type for 'hour'
      minute: '2-digit',    // Correct type for 'minute'
      timeZone: 'Asia/Bangkok', // GMT+7 timezone
      hour12: false,        // 24-hour format
      timeZoneName: 'short' // Includes 'GMT+7'
    };

    formattedTime = new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  return formattedTime;
};

export function StudentTrackRegistration() {
  const { showModal } = useModal();

  const [bulkReg, setBulkReg] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // Dropdown Data
  const [listTerm, setListTerm] = useState<TermDataList[]>([]);
  const [dropInstitution, setDropInstitution] = useState<
    DropdownResponseData[]
  >([]);
  const [dropCampus, setDropCampus] = useState<DropdownResponseData[]>([]);
  const [dropAcadCareer, setDropAcadCareer] = useState<DropdownResponseData[]>(
    []
  );
  const [dataAcadOrg, setDataAcadOrg] = useState<AcadOrgDropdownResponseData[]>([] as AcadOrgDropdownResponseData[]);
  const [dropAcadGroup, setDropAcadGroup] = useState<DropdownResponseData[]>(
    []
  );
  const [dropAcadProg, setDropAcadProg] = useState<DropdownResponseData[]>([]);
  const [dropAcadPlan, setDropAcadPlan] = useState<DropdownResponseData[]>([]);

  // Value Dropdown
  const [trackValue, setTrackValue] = useState("");
  const [institutionValue, setInstitutionValue] = useState("");
  const [campusValue, setCampusValue] = useState("");
  const [acadCareerValue, setAcadCareerValue] = useState("");
  const [acadGroupValue, setAcadGroupValue] = useState("");
  const [acadProgValue, setAcadProgValue] = useState("");
  const [acadPlanValue, setAcadPlanValue] = useState("");
  const [acadOrgValue, setAcadOrgValue] = useState("");

  const [dataBulk, setDataBulk] = useState<StudentDataResponse[]>([]);
  const [listAssign, setListAssign] = useState<AssigmentPartnerData[]>([]);
  const [listTrack, setListTrack] = useState<TrackData[]>([]);
  const [assignValue, setAssignValue] = useState("");
  const [reason, setReason] = useState("");
  const [modalUpload, setModalUpload] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [nim, setNim] = useState("");
  const [showBox, setShowBox] = useState(false);
  const [acadProgList, setAcadProgList] = useState<DropdownResponseData[]>([]);
  const [admitTermList, setAdmitTermList] = useState<DropdownResponseData[]>(
    []
  );
  const [dataIndividual, setDataIndividual] = useState<GetIndividualData>(
    {} as GetIndividualData
  );
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [termSelect, setTermSelect] = useState<string | null>(null);
  const [acadProgSelect, setAcadProgSelect] = useState("");
  const [statusSelect, setStatusSelect] = useState("");
  const [admitTermSelect, setAdmitTermSelect] = useState<string | null>(null);
  const [selectEnrichmentPeriod, setSelectEnrichmentPeriod] = useState("");

  const [btnDownloadReport, setBtnDownloadReport] = useState(true);

  const [openDetailEnrichmentTrackModal, setOpenDetailEnrichmentTrackModal] = useState(false);
  const [doGetDetailStudentEnrichmentTrack, setDoGetDetailStudentEnrichmentTrack] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [studentTrackPriority1, setStudentTrackPriority1] = useState("");
  const [studentTrackPriority2, setStudentTrackPriority2] = useState("");

  const columns: GridColDef[] = [
    {
      field: "externalSystemId",
      headerName: "Student ID",
      flex: 1,
      hideable: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      hideable: false,
    },
    {
      field: "acadProgDesc",
      headerName: "Academic Program",
      flex: 1,
      hideable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      hideable: false,
      renderCell: (params) =>
        params.value === "1" ? (
          <Chip color="warning" component="div" label="Registered" />
        ) : (
          <Chip color="error" component="div" label="Not Registered" />
        ),
    },
    {
      field: "registrationDate",
      headerName: "Registration Date",
      flex: 1,
      hideable: false,
      renderCell: (params) => <div>{params.row.dateUp ? formatDate(params.row.dateUp) : "-"}</div>,
    },
    {
      field: "registrationTime",
      headerName: "Registration Time",
      flex: 1,
      hideable: false,
      renderCell: (params) => <div>{params.row.dateUp ? formatTime(params.row.dateUp) : "-"}</div>,
    },
    {
      field: "trackDesc",
      headerName: "Approved Track",
      flex: 1,
      hideable: false,
      renderCell: (params) =>
        params.value !== null ? (
          params.value
        ) : (
          <>
            -
          </>
        ),
    },
    {
      field: "detail",
      headerName: "Action",
      hideable: false,
      align: "center",
      renderCell: (params) => (
        <IconButton onClick={() => {
          setStudentId(params.row.id);
          setDoGetDetailStudentEnrichmentTrack(true);
          setOpenDetailEnrichmentTrackModal(true);
        }}>
          <RemoveRedEyeOutlinedIcon />
        </IconButton>
      )
    },
  ];

  const getBulkRegistration = useMutationStudentBulk({
    query: {
      search: "",
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page + 1,
      orderBy: "name:desc",
    },
    options: {
      onSuccess: async (res: any) => {
        setDataBulk(res.data);
        setBtnDownloadReport(false);
        setTotalItems(res.paginationInfo.totalItems || 0)
      },
      onError: async (err) => {
        setDataBulk([]);
      }
    }
  });

  const submitUploadRegistration = useMutationUploadRegistration({
    onSuccess: async (res: any) => {
      getBulkRegistration.mutate({
        institution: institutionValue,
        acadCareer: acadCareerValue,
        acadOrg: acadOrgValue,
        acadProg: acadProgValue,
        acadPlan: acadPlanValue,
        studentId: nim,
        period: termSelect,
        admitTerm: admitTermSelect,
        status: statusSelect,
      });

      setOpenUploadModal(false);
      showModal({
        title: "Success",
        message: res.message,
        options: {
          variant: "success",
        },
      });
    },
    onError: (err) => {
      setOpenUploadModal(false);

      const checkError = err?.response?.data.message;

      const errorMessage = checkError === "Error" 
      ? err?.response?.data.details 
      : err?.response?.data.message;

      showModal({
        title: "Failed",
        message: errorMessage || "Submit student track registration is failed.",
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const downloadTemplate = useMutationDownloadTemplate({
    onSuccess: async (res: any) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `Template_Bulk_Registration.xlsx`;
      link.click();
      window.URL.revokeObjectURL(url);
    },
    onError: async (err) => {
      showModal({
        title: "Failed",
        message: "Download template is failed.",
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const downloadReport = useMutationDownloadReport({
    onSuccess: async (res: any) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `Report_Bulk_Registration.xlsx`;
      link.click();
      window.URL.revokeObjectURL(url);
    },
    onError: async (err) => {
      showModal({
        title: "Failed",
        message: "Download report is failed.",
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDropInstitution(res.data || []);
        if (res.data && res.data.length > 0) {
          setInstitutionValue("BNS01");
        }
      },
      onError: () => {
        setDropInstitution([]);
      },
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDropAcadCareer(res.data || []);
        setAcadCareerValue("OS1");
      },
      onError: () => {
        setDropAcadCareer([]);
      },
    },
  });

  const getAcadOrg = useQueryAcadOrg({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataAcadOrg(res.data || []);
      },
      onError: () => {
        setDataAcadOrg([]);
      },
    },
  });

  const getAcadProg = useQueryAcadProg({
    query: {
      acadOrg: acadOrgValue
    },
    options: {
      enabled: !!acadOrgValue,
      onSuccess: (res) => {
        setDropAcadProg(res.data || []);
      },
      onError: () => {
        setDropAcadProg([]);
      },
    },
  });

  const getAcadPlan = useQueryAcadPlan({
    query: {
      acadOrg: acadOrgValue,
      acadProg: acadProgValue
    },
    options: {
      enabled: !!acadOrgValue && !!acadProgValue,
      onSuccess: (res) => {
        setDropAcadPlan(res.data || []);
      },
      onError: () => {
        setDropAcadPlan([]);
      },
    },
  });

  const getTerm = useQueryGetTermDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setListTerm(res.data || []);
      },
      onError: () => {
        setListTerm([]);
      },
    },
  });

  const getAdmitTerm = useQueryDropDownAdmitTerm({
    query: {},
    options: {
      onSuccess: (res) => {
        setAdmitTermList(res.data || []);
      },
      onError: () => {
        setAdmitTermList([]);
      },
    },
  });

  const getAssign = useQueryGetAssignPartnerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setListAssign(res.data || []);
      },
      onError: () => {
        setListAssign([]);
      },
    },
  });

  const getTrack = useQueryGetTrackDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setListTrack(res.data || []);
      },
      onError: () => {
        setListTrack([]);
      },
    },
  });

  const handleSubmitIndividual = () => {
    getIndividual.refetch();
  };

  const getIndividual = useQueryGetIndividual({
    query: {
      studentId: nim,
    },
    options: {
      enabled: false,
      retry: false,
      onSuccess: (res) => {
        setDataIndividual(res.data as GetIndividualData);
        setTrackValue(res.data?.track ?? "MP");
        setReason(res.data?.reason ?? "-");
        setShowBox(true);
      },
      onError: (err: any) => {
        showModal({
          title: "Failed",
          message: err.response.data.message,
          options: {
            buttonTitle: "Ok",
            variant: "failed",
          },
        });
      },
    },
  });

  const submitTrackRegIndividual = useMutationSubmitStudentTrackRegistration({
    query: {
      institution: dataIndividual.institution,
      acadCareer: dataIndividual.acadCareer,
      acadOrg: dataIndividual.acadOrg,
      acadProg: dataIndividual.acadProg,
      acadPlan: dataIndividual.acadPlan,
      studentId: nim,
      period: dataIndividual.termDesc,
      admitTerm: dataIndividual.admitTerm,
      status: dataIndividual.status,
    },
    options: {
      onSuccess: async (res: any) => {
        setShowBox(false)
        showModal({
          title: "Success",
          message: `Submit student track registration success!`,
          options: {
            buttonTitle: "Ok",
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        const checkError = err?.response?.data.message;

        const errorMessage = checkError === "Error" 
        ? err?.response?.data.details 
        : err?.response?.data.message;

        showModal({
          title: "Failed",
          message: errorMessage || "Submit student track registration is failed.",
          options: {
            buttonTitle: "Ok",
            variant: "failed",
          },
        });
      },
    },
  });

  const submitIndividualStudentTrack = async () => {
    submitTrackRegIndividual.mutate({
      track: trackValue,
      reAssignPartner: assignValue,
      reason,
    });
  };

  const submitDataBulk = async () => {
    getBulkRegistration.mutate({
      institution: institutionValue,
      acadCareer: acadCareerValue,
      acadOrg: acadOrgValue,
      acadProg: acadProgValue,
      acadPlan: acadPlanValue,
      studentId: nim,
      period: termSelect,
      admitTerm: admitTermSelect,
      status: statusSelect,

      // institution: "BNS01",
      // acadCareer: "RS2",
      // acadOrg: "MCSC",
      // acadProg: "KMMCS",
      // acadPlan: "KMSTKMMCS",
      // studentId: "2402226423",
      // period: "2421",
      // admitTerm: "2422",
      // status: "1",
    });
  };

  const downloadTemplateBulk = async () => {
    downloadTemplate.mutate({
      institution: "",
      studentId: "",
      acadOrg: "",
      acadCareer: "",
      acadProg: "",
      acadPlan: "",
      status: "",
      period: "",
      admitTerm: "",
    });
  };

  const downloadReporteBulk = async () => {
    downloadReport.mutate({
      institution: institutionValue,
      studentId: nim,
      acadCareer: acadCareerValue,
      acadOrg: acadOrgValue,
      acadProg: acadProgValue,
      acadPlan: acadPlanValue,
      status: statusSelect,
      period: termSelect,
      admitTerm: admitTermSelect
    });
  };

  const getDetailStudentEnrichmentTrack = useQueryGetDetailStudentEnrichmentTrack({
    query: {
      id: studentId,
    },
    options: {
      enabled: doGetDetailStudentEnrichmentTrack,
      onSuccess: (res) => {
        setStudentTrackPriority1(res.data.firstPriorityDesc !== null ? res.data.firstPriorityDesc : "-");
        setStudentTrackPriority2(res.data.secondPriorityDesc !== null ? res.data.secondPriorityDesc : "-");
      },
      onError: (res) => {
        console.log(res);
      },
    },
  });

  const handleUploadChange = (newValue: File | null) => {
    const file = newValue;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB limit

    if (file && file.size > maxSizeInBytes) {
      showModal({
        title: "Upload Failed",
        message: "File size exceeds 5 MB.",
        options: {
          variant: "failed",
        },
      });

      setUploadFile(null);
    } else {
      setUploadFile(file);
    }
  };

  const sumbitUpload = () => {
    const formData = new FormData();

    uploadFile
      ? formData.append("file", uploadFile)
      : formData.append("file", new Blob());
    formData.append("institution", institutionValue);
    formData.append("acadCareer", acadCareerValue);
    formData.append("acadOrg", acadOrgValue);
    formData.append("acadProg", acadProgValue);
    formData.append("acadPlan", acadPlanValue);
    formData.append("period", termSelect ?? "");
    formData.append("studentId", nim);

    submitUploadRegistration.mutate(formData);
  };

  useEffect(() => {
    if (doGetDetailStudentEnrichmentTrack) {
      getDetailStudentEnrichmentTrack.refetch();
    }
  }, [studentId]);

  useEffect(() => {
    submitDataBulk();
  }, [paginationModel]); 

  return (
  <>
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Student Registration
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Student Track Registration
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Student Track Registration
      </Typography>

      <Divider />

      <Grid display="flex">
        <Grid xs={2}>
          <Box
            pt={1}
            pb={1}
            mr={1}
            sx={
              !bulkReg
                ? StudentRegCss.headerActive
                : StudentRegCss.headerInactive
            }
          >
            <Button onClick={() => setBulkReg(false)}>
              <Typography variant="h6" gutterBottom>
                Individual Registration
              </Typography>
            </Button>
          </Box>
        </Grid>

        <Grid xs={2}>
          <Grid xs={2}>
            <Box
              pt={1}
              pb={1}
              ml={1}
              sx={
                bulkReg
                  ? StudentRegCss.headerActive
                  : StudentRegCss.headerInactive
              }
            >
              <Button onClick={() => setBulkReg(true)}>
                <Typography variant="h6" gutterBottom>
                  Bulk Registration
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      {bulkReg ? (
        <Box mt={2} mb={2}>
          <Grid container spacing={2}>
            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="institution-select-label">Institution</InputLabel>
                <Select
                  disabled
                  labelId="institution-select-label"
                  id="institution-select"
                  label="Institution"
                  value={institutionValue}
                  onChange={(e) => {
                    setInstitutionValue(e.target.value as string);
                  }}
                >
                  {dropInstitution.map((item) => (
                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="acadCareer-select-label">Academic Career</InputLabel>
                <Select
                  disabled
                  labelId="acadCareer-select-label"
                  id="acadCareer-select"
                  label="Academic Career"
                  value={acadCareerValue}
                  onChange={(e) => {
                    setAcadCareerValue(e.target.value as string);
                  }}
                >
                  {dropAcadCareer.map((item) => (
                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="acadOrganization-select-label"
                  // shrink
                >
                  Academic Organization
                </InputLabel>
                <Select
                  // displayEmpty
                  labelId="acadOrganization-select-label"
                  id="acadOrganization-select"
                  label="Academic Organization"
                  value={acadOrgValue}
                  // renderValue={(value) => value === "" ? "All" : value}
                  onChange={(e) => {
                    setAcadOrgValue(e.target.value as string);
                  }}
                >
                    {/* <MenuItem value=""><em>All</em></MenuItem> */}
                  {dataAcadOrg.map((item) => (
                    <MenuItem value={item.Code}>{item.Code} - {item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="acadProgram-select-label"
                  // shrink
                >
                  Academic Program
                </InputLabel>
                <Select
                  // displayEmpty
                  labelId="acadProgram-select-label"
                  id="acadProgram-select"
                  label="Academic Program"
                  value={acadProgValue}
                  // renderValue={(value) => value === "" ? "All" : value}
                  onChange={(e) => {
                    setAcadProgValue(e.target.value as string);
                  }}
                >
                    {/* <MenuItem value=""><em>All</em></MenuItem> */}
                  {dropAcadProg.map((item) => (
                    <MenuItem value={item.Code}>{item.Code} - {item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="acadPlan-select-label" 
                  // shrink
                >
                  Academic Plan
                </InputLabel>
                <Select
                  // displayEmpty
                  labelId="acadPlan-select-label"
                  id="acadPlan-select"
                  label="Academic Plan"
                  value={acadPlanValue}
                  // renderValue={(value) => value === "" ? "All" : value}
                  onChange={(e) => {
                    setAcadPlanValue(e.target.value as string);
                  }}
                >
                    {/* <MenuItem value=""><em>All</em></MenuItem> */}
                  {dropAcadPlan.map((item) => (
                    <MenuItem value={item.Code}>{item.Code} - {item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                size="small"
                value={listTerm.find(term => term.Code === termSelect) || null}
                onChange={(event, newValue) => {
                  setTermSelect(newValue ? newValue.Code : null);
                }}
                id="period-select"
                options={listTerm}
                getOptionLabel={(option) => option.Name}
                renderInput={(params) => <TextField {...params} label="Period" />}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField 
                fullWidth
                size="small" 
                id="studentId"
                label="Student ID" 
                variant="outlined"
                value={nim}
                onChange={(e) => setNim(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                size="small"
                value={admitTermList.find(term => term.Code === admitTermSelect) || null}
                onChange={(event, newValue) => {
                  setAdmitTermSelect(newValue ? newValue.Code : null);
                }}
                id="admitTerm-select"
                options={admitTermList}
                getOptionLabel={(option) => option.Name}
                renderInput={(params) => <TextField {...params} label="Admit Term" />}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="status-select-label"
                  // shrink
                >
                  Status
                </InputLabel>
                <Select
                  // displayEmpty
                  labelId="status-select-label"
                  id="status-select"
                  label="Status"
                  value={statusSelect}
                  // renderValue={(value) => value === "" ? "All" : value}
                  onChange={(e) => {
                    setStatusSelect(e.target.value as string);
                  }}
                >
                  {/* <MenuItem value=""><em>None</em></MenuItem> */}
                  <MenuItem key="1" value="1">Registered</MenuItem>
                  <MenuItem key="2" value="0">Not Registered</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid display="flex" justifyContent="space-between" xs={12} item>
              <Box component="div">
                <Button
                  href="/Template Bulk Student Track Registration.xlsx"
                  sx={{ marginRight: "8px" }}
                  variant="contained"
                >
                  Download Template
                </Button>
                
                <Button
                  onClick={() => setOpenUploadModal(true)}
                  sx={{ marginLeft: "8px" }}
                  variant="contained"
                >
                  Upload Registration
                </Button>
              </Box>

              <Box component="div">
                <Button onClick={() => submitDataBulk()} variant="contained">
                  Search
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Grid spacing={2} mt={2} mb={2} container>
            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="institution-select-label">Institution</InputLabel>
                <Select
                  disabled
                  labelId="institution-select-label"
                  id="institution-select"
                  label="Institution"
                  value={institutionValue}
                  onChange={(e) => {
                    setInstitutionValue(e.target.value as string);
                  }}
                >
                  {dropInstitution.map((item) => (
                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <FormControl fullWidth size="small">
                <InputLabel id="academicCareer-select-label">Academic Career</InputLabel>
                <Select
                  disabled
                  labelId="academicCareer-select-label"
                  id="academicCareer-select"
                  label="academicCareer"
                  value={acadCareerValue}
                  onChange={(e) => {
                    setAcadCareerValue(e.target.value as string);
                  }}
                >
                  {dropAcadCareer.map((item) => (
                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid mb={2} xs={6} item>
              <TextField
                fullWidth
                size="small" 
                id="studentId"
                label={
                  <>
                    Student ID<Typography component="span" color="error">*</Typography>
                  </>
                } 
                variant="outlined"
                value={nim}
                onChange={(e) => setNim(e.target.value)}
              />
            </Grid>

            <Grid display="flex" justifyContent="end" xs={12} item>
              <Button
                onClick={() => handleSubmitIndividual()}
                variant="contained"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <Divider />

      {bulkReg ? (
        <Box component="div" mt={2} mb={2}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="end">
              <Button
                disabled={btnDownloadReport}
                variant="contained"
                sx={{ background: "#0097da" }}
                onClick={() => downloadReporteBulk()}
              >
                Download Report
              </Button>
            </Grid>

            {/* <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="end"
                sx={StudentRegCss.searchBar}
              >
                <OutlinedInput
                  sx={StudentRegCss.inputSearch}
                  placeholder="Search"
                  fullWidth
                />
                <Search sx={StudentRegCss.iconSearch} />
              </Box>
            </Grid> */}

            <Grid item xs={12}>
              <Box mt={3}>
                <Paper elevation={3}>
                  <CustomDataGrid
                    columnsData={columns}
                    rowsData={dataBulk}
                    rowCount={totalItems}
                    getRowId={(row) => row.id}
                    setPaginationModel={(value) => {
                      setPaginationModel(value);
                    }}
                    paginationModel={paginationModel}
                  />
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        showBox && (
          <Box mt={2} mb={2} component="div">
            <Typography variant="h6" mb={2}>
              Showing Form for Term : {dataIndividual.termDesc || "-"}
            </Typography>

            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="studentId"
                  label="Student ID"
                  variant="outlined"
                  value={dataIndividual.externalSystemId || "-"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="studentName"
                  label="Student Name"
                  variant="outlined"
                  value={dataIndividual.name || "-"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="faculty/school"
                  label="Faculty / School"
                  variant="outlined"
                  value={dataIndividual.campusDesc || "-"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="academicProgram"
                  label="Academic Program"
                  variant="outlined"
                  value={dataIndividual.acadProgDesc || "-"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="academicOrganization"
                  label="Academic Organization"
                  variant="outlined"
                  value={dataIndividual.acadOrgDesc || "-"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="academicPlan"
                  label="Academic Plan"
                  variant="outlined"
                  value={dataIndividual.acadPlanDesc || "-"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="Period"
                  label="Period"
                  variant="outlined"
                  value={dataIndividual.termDesc || "-"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small" 
                  id="admitTerm"
                  label="Admit Term"
                  variant="outlined"
                  value={dataIndividual.termDesc || "-"}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="track-select-label">Track<span style={{ color: "red" }}>*</span></InputLabel>
                  <Select
                    labelId="track-select-label"
                    id="track-select"
                    label="Track"
                    value={trackValue}
                    onChange={(e) => {
                      setTrackValue(e.target.value as string);
                    }}
                  >
                    {listTrack.map((item) => (
                      <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="reason"
                  label={
                    <>Reason <span style={{ color: "red" }}>*</span></>
                  }
                  variant="outlined"
                  multiline
                  rows={4}
                  placeholder="Type here"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Grid>

              <Grid display="flex" justifyContent="end" xs={12} item>
                <Button
                  onClick={submitIndividualStudentTrack}
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        )
      )}
    </Container>
    <Modal
      open={openUploadModal}
      onClose={() => {
        setOpenUploadModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={StudentRegCss.modalUploadStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontWeight="bold"
        >
          Bulk Student Registration
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenUploadModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-modal-description"
          fontSize={12}
          mt={2}
          mb={1}
        >
          Upload Here
        </Typography>
        <OutlinedInput
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleUploadChange(event.target.files?.[0] || null)
          }
          inputProps={{
            accept:
              ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }}
          type="file"
          fullWidth
        />
        <Box mt={2} display="flex" justifyContent="end">
          <Button
            onClick={() => sumbitUpload()}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
    <Dialog
        open={openDetailEnrichmentTrackModal}
        onClose={() => {
          setDoGetDetailStudentEnrichmentTrack(false);
          setOpenDetailEnrichmentTrackModal(false);
        }}
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Enrichment Track
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDoGetDetailStudentEnrichmentTrack(false);
            setOpenDetailEnrichmentTrackModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="priority1"
                label="Priority 1"
                variant="outlined"
                size="small"
                value={studentTrackPriority1}
                onChange={(e) => setStudentTrackPriority1(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="priority2"
                label="Priority 2"
                variant="outlined"
                size="small"
                value={studentTrackPriority2}
                onChange={(e) => setStudentTrackPriority2(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            setDoGetDetailStudentEnrichmentTrack(false);
            setOpenDetailEnrichmentTrackModal(false)
          }}>
            Close
          </Button>
        </DialogActions>
    </Dialog>
  </>
  );
}
