import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useQueryListRoleEdit } from "../../api/user-management";
import { RoleListResponseEdit } from "../../types/user-role";

interface MyComponentProps {
  setDataRole: any;
  setDataRoleName: any;
  setDataRoleStatus: any;
  idRoleList: any;
}

export default function CheckboxListEdit({
  setDataRole,
  setDataRoleName,
  setDataRoleStatus,
  idRoleList,
}: MyComponentProps) {
  const [listRole, setListRole] = useState<RoleListResponseEdit[]>([]);
  const [roleName, setRoleName] = useState("");
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
  const [dropdown, setDropdown] = useState<{ [key: string]: boolean }>({});

  const handleParentCheck = (
    category: string,
    items: RoleListResponseEdit,
    isChecked: boolean
  ) => {
    setChecked((prev) => {
      const updatedChecked = { ...prev };
      items.forEach((item) => {
        updatedChecked[item.id] = isChecked;
      });
      return updatedChecked;
    });
  };

  const handleChildCheck = (item: RoleListResponseEdit, isChecked: boolean) => {
    setChecked((prev) => ({
      ...prev,
      [item.id]: isChecked,
    }));
  };

  const getRoleDetail = useQueryListRoleEdit({
    query: {
      roleId: idRoleList,
    },
    options: {
      onSuccess: (res) => {
        setListRole(res.listRole || []);
        setActive(res.isActive || false);
        setRoleName(res.name || "");
        const initialChecked: { [key: string]: boolean } = {};
        Object.values(res.listRole || [])
          .flat()
          .forEach((item) => {
            initialChecked[item.id] = item.canRead === 1;
          });
        setChecked(initialChecked);
      },
      onError: () => {
        alert("Error");
      },
    },
  });

  useEffect(() => {
    const submittedData: { menuId: string; canRead: number }[] = [];
    Object.entries(listRole).forEach(([category, items]) => {
      items.forEach((item) => {
        submittedData.push({
          menuId: item.id,
          canRead: checked[item.id] ? 1 : 0,
        });
      });
    });
    setDataRole(submittedData);
    setDataRoleName(roleName);
    setDataRoleStatus(active ? "Active" : "Nonactive");
  }, [
    active,
    checked,
    listRole,
    roleName,
    setDataRole,
    setDataRoleName,
    setDataRoleStatus,
  ]);

  return (
    <>
      {Object.entries(listRole).map(([label, items]) => {
        const categoryKey = label.replace(/\s+/g, "");
        const dropCategory = dropdown[categoryKey];
        const allChecked = items.every((item) => checked[item.id]);
        const someChecked = items.some((item) => checked[item.id]);

        return (
          <div key={label}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {items.length > 1 && (
                <IconButton
                  onClick={() =>
                    setDropdown((prev) => ({
                      ...prev,
                      [categoryKey]: !dropCategory,
                    }))
                  }
                >
                  {dropCategory ? <ExpandMore /> : <KeyboardArrowRightIcon />}
                </IconButton>
              )}
              <FormControlLabel
                label={label}
                control={
                  <Checkbox
                    sx={items.length <= 1 ? { marginLeft: "40px" } : null}
                    checked={allChecked}
                    indeterminate={Boolean(!allChecked && someChecked)}
                    onChange={(event) =>
                      handleParentCheck(
                        categoryKey,
                        items,
                        event.target.checked
                      )
                    }
                  />
                }
              />
            </Box>
            {dropCategory && items.length > 1 && (
              <Box sx={{ marginLeft: "50px" }}>
                {items.map((item) => (
                  <Box sx={{ display: "flex" }} key={item.id}>
                    <Checkbox
                      checked={checked[item.id]}
                      onChange={(event) =>
                        handleChildCheck(item, event.target.checked)
                      }
                    />
                    <Typography
                      sx={{ marginTop: "10px" }}
                      fontSize={14}
                      color="#333333"
                    >
                      {item.menuName}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Divider />
          </div>
        );
      })}
    </>
  );
}
