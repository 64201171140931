import {
  Container,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Breadcrumbs,
  Skeleton
} from "@mui/material";
import LinkMui from "@mui/material/Link";
import AddOutlined from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

import { MuiFileInput } from "mui-file-input";
import { styled } from "@mui/material/styles";

import { useState } from "react";
import { useSelector } from "react-redux";
import useModal from "../../hooks/use-modal";

import { TextComp } from "../../components/atom/text-comp";
import { useNavigate, Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { selectAuthUser } from "../../store/auth/selector";
import {
  JobStatusData,
  BusinessSectorData,
  PositionData,
  ProvinceData,
  CountryData,
  SkillTypeData,
  ProfileData,
} from "../../types/enrichment-track";
import {
  useQueryGetJobStatus,
  useQueryGetBusinessSector,
  useQueryGetPosition,
  useQueryGetProvince,
  useQueryGetCountry,
  useQueryGetSkillType,
  useQueryGetProfile,
  useMutationSubmitProfile,
} from "../../api/enrichment-track";

export const StyledMuiFileInput = styled(MuiFileInput)(({ theme }) => ({
  background: "white",
  border: "1px solid #CCCCCC",
  borderRadius: "6px",
}));

export function EditProfile() {
  const { showModal } = useModal();
  const Navigate = useNavigate();
  const profile = useSelector(selectAuthUser);

  const [listSkill, setListSkill] = useState<
    {
      skillId: string;
      skillTypeId: string;
      skillLevel: string;
      skillName: string;
    }[]
  >([{ skillId: "", skillTypeId: "", skillLevel: "", skillName: "" }]);

  const [dataJobStatus, setDataJobStatus] = useState<JobStatusData[]>([]);
  const [dataBusinessSector, setDataBusinessSector] = useState<
    BusinessSectorData[]
  >([]);
  const [dataPosition, setDataPosition] = useState<PositionData[]>([]);
  const [dataProvince, setDataProvince] = useState<ProvinceData[]>([]);
  const [dataCountry, setDataCountry] = useState<CountryData[]>([]);
  const [dataSkillType, setDataSkillType] = useState<SkillTypeData[]>([]);
  const [dataProfile, setDataProfile] = useState<ProfileData | null>(null);

  const [jobStatus, setJobStatus] = useState("");
  const [businessSector, setBusinessSector] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDomicile, setCompanyDomicile] = useState("Dalam Negeri");
  const [provinsi, setProvinsi] = useState("");
  const [negara, setNegara] = useState("");
  const [position, setPosition] = useState("");
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [uploadName, setUploadName] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [facebook, setFacebook] = useState("");
  const [line, setLine] = useState("");
  const [portofolio, setPortofolio] = useState("");

  const getJobStatus = useQueryGetJobStatus({
    options: {
      onSuccess: (res) => {
        setDataJobStatus(res.data);
      },
      onError: () => {
        setDataJobStatus([]);
      },
    },
  });

  const getBusinessSector = useQueryGetBusinessSector({
    options: {
      onSuccess: (res) => {
        setDataBusinessSector(res.data);
      },
      onError: () => {
        setDataBusinessSector([]);
      },
    },
  });

  const getPosition = useQueryGetPosition({
    options: {
      onSuccess: (res) => {
        setDataPosition(res.data);
      },
      onError: () => {
        setDataPosition([]);
      },
    },
  });

  const getProvince = useQueryGetProvince({
    options: {
      onSuccess: (res) => {
        setDataProvince(res.data);
      },
      onError: () => {
        setDataProvince([]);
      },
    },
  });

  const getCountry = useQueryGetCountry({
    options: {
      onSuccess: (res) => {
        setDataCountry(res.data);
      },
      onError: () => {
        setDataCountry([]);
      },
    },
  });

  const getSkillType = useQueryGetSkillType({
    options: {
      onSuccess: (res) => {
        setDataSkillType(res.data);
      },
      onError: () => {
        setDataSkillType([]);
      },
    },
  });

  const getProfile = useQueryGetProfile({
    query: {
      binusianid: profile.binusianId,
    },
    options: {
      onSuccess: (res) => {
        setDataProfile(res.data);
        setJobStatus(res.data.jobStatusId);
        setBusinessSector(res.data.businessSectorId);
        setCompanyName(res.data.companyName);
        setCompanyDomicile(res.data.companyDomicile);
        setProvinsi(res.data.province);
        setNegara(res.data.country);
        setPosition(res.data.jobPositionId);
        setFacebook(res.data.facebook);
        setLinkedin(res.data.linkedIn);
        setLine(res.data.line);
        setPortofolio(res.data.portofolio);

        res.data.skill.length > 0 && setListSkill(res.data.skill);

        setUploadUrl(res.data.employeeCertificate);
        setUploadName(res.data.employeeCertificateName);
      },
      onError: () => {
        setDataProfile(null);
      },
    },
  });

  const submitProfile = useMutationSubmitProfile({
    onSuccess: async (res) => {
      showModal({
        title: "Success",
        message: "Submit profile successfully submitted.",
        options: {
          variant: "success",
          onOk: () => {
            Navigate("/enrichment/update-profile");
          },
        },
      });
    },
    onError: (err) => {
      showModal({
        title: "Failed",
        message: err.response?.data.message || "Submit profile is failed.",
        options: {
          variant: "failed",
        },
      });
    },
  });

  const validateLinkedInUrl = (url) => {
    // Check if the URL contains "https://www.linkedin.com/"
    if (url.includes("https://www.linkedin.com/")) {
      setErrorMessage(""); // Clear any previous error message
    } else {
      setErrorMessage(
        "Please enter a valid LinkedIn URL, e.g., https://www.linkedin.com/in/link-person-profile"
      );
    }
  };

  const handleChangeJobStatus = (e) => {
    const newJobStatus = e.target.value;
    setJobStatus(newJobStatus);

    if(newJobStatus === "1"){
      setBusinessSector("");
      setUploadName("");
    }

    if(newJobStatus === "2" || newJobStatus === "3"){
      setBusinessSector("");
      setCompanyName("");
      setCompanyDomicile("");
      setProvinsi("");
      setNegara("");
      setPosition("");
      setUploadName("");
    }
  };

  const handleLinkedinChange = (url) => {
    console.log(url, "URL");
    validateLinkedInUrl(url);
    setLinkedin(url);
  };

  const handleUploadChange = (newValue: File | null) => {
    const file = newValue;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB limit

    if (file && file.size > maxSizeInBytes) {
      showModal({
        title: "Upload Failed",
        message: "File size exceeds 5 MB.",
        options: {
          variant: "failed",
        },
      });

      setUploadFile(null);
    } else {
      setUploadFile(file);
    }
  };

  const handleSkillChange = (index, e) => {
    const { name, value } = e.target;
    const newSkills = listSkill.map((skill, skillIndex) => {
      if (index === skillIndex) {
        // return { ...skill, [name]: value };
        return {
          ...skill,
          [name]: name === "skillName" && value.length > 30 ? skill.skillName : value,
        };
      }
      return skill;
    });

    setListSkill(newSkills);
  };

  const handleAddFields = () => {
    setListSkill([
      ...listSkill,
      { skillId: "", skillTypeId: "", skillLevel: "", skillName: "" },
    ]);
  };

  const handleUpdate = () => {
    const data = {
      id: dataProfile?.id,
      binusianId: dataProfile?.binusianId,
      externalSystemId: dataProfile?.externalSystemId,
      businessSectorId: businessSector,
      businessSectorDesc: "",
      companyDomicile,
      companyName,
      employeeCertificate: dataProfile?.employeeCertificate,
      jobPositionId: position,
      jobPosition: "",
      jobStatusId: jobStatus,
      jobStatusDesc: "",
      linkedIn: linkedin,
      facebook,
      line,
      portofolio,
      province: companyDomicile === "Dalam Negeri" ? provinsi : "",
      country: companyDomicile === "Luar Negeri" ? negara : "",
      skill: listSkill,
    };
    console.log(data);

    const formData = new FormData();
    uploadFile
      ? formData.append("file", uploadFile)
      : formData.append("file", "");
    formData.append("data", JSON.stringify(data));

    submitProfile.mutate(formData);
  };

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Enrichment
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Update Profile
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2} component="div">
        Update Your Profile
      </Typography>
      <Divider />

      <Grid container spacing={2} mt={1}>
      {dataProfile ? (
        <>
          <Grid item xs={5}>
            <TextComp title="Semester / Term" bodyText={dataProfile?.termDesc} />
            <TextComp
              title="NIM / Student Id"
              bodyText={dataProfile?.externalSystemId}
            />
            <TextComp
              title="Nama Lengkap /  Full Name"
              bodyText={dataProfile?.name}
            />
          </Grid>
          <Grid item xs={7}>
            <TextComp
              title="Jenjang Pendidikan / Academic Career"
              bodyText={dataProfile?.acadCareerDesc}
            />
            <TextComp
              title="Jurusan / Academic Program"
              bodyText={dataProfile?.acadProgDesc}
            />
            <TextComp
              title="Periode Masuk /  Admit Term"
              bodyText={dataProfile?.admitTermDesc}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={5} mb={3}>
            <Stack>
              <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" sx={{ fontSize: '14px' }} />
            </Stack>
          </Grid>
          <Grid item xs={7} mb={3}>
            <Stack>
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
            </Stack>
          </Grid>
        </>
      )}
      </Grid>
      <Divider />

      <Grid container spacing={2} mt={1} mb={3}>
      {dataProfile ? (
      <>
        <Grid item xs={6}>
          <FormControl size="small" fullWidth>
            <InputLabel id="job-status-select">
              Status Bekerja<Typography component="span" color="error">*</Typography>
            </InputLabel>
            <Select
              labelId="job-status-select"
              id="job-status"
              value={jobStatus}
              label="Status Bekerja"
              onChange={handleChangeJobStatus}
            >
              {dataJobStatus.map((item) => (
                <MenuItem key={item.Id} value={item.Id}>
                  {item.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {jobStatus === "1" && (
        <>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="business-sector-select">Bidang Usaha</InputLabel>
              <Select
                labelId="business-sector-select"
                id="business-sector"
                value={businessSector}
                label="Bidang Usaha"
                onChange={(e) => setBusinessSector(e.target.value)}
              >
                {dataBusinessSector.map((item) => (
                  <MenuItem key={item.Id} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="company-name"
              label="Nama Perusahaan"
              variant="outlined"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12} color="#333333" variant="body2">
              Domisili Perusahaan
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={companyDomicile}
                onChange={(e) => setCompanyDomicile(e.target.value)}
              >
                <FormControlLabel
                  value="Dalam Negeri"
                  control={<Radio size="small" />}
                  label="Dalam Negeri"
                />
                <FormControlLabel
                  value="Luar Negeri"
                  control={<Radio size="small" />}
                  label="Luar Negeri"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

        {companyDomicile === "Dalam Negeri" && (
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="provinsi-select">Provinsi</InputLabel>
              <Select
                labelId="provinsi-select"
                id="provinsi"
                value={provinsi}
                label="Provinsi"
                onChange={(e) => setProvinsi(e.target.value)}
              >
                {dataProvince.map((item) => (
                  <MenuItem key={item.Id} value={item.Name}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {companyDomicile === "Luar Negeri" && (
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="negara-select">Negara</InputLabel>
              <Select
                labelId="negara-select"
                id="negara"
                value={negara}
                label="Negara"
                onChange={(e) => setNegara(e.target.value)}
              >
                {dataCountry.map((item) => (
                  <MenuItem key={item.Id} value={item.Name}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="position-select">Jabatan</InputLabel>
              <Select
                labelId="position-select"
                id="position"
                value={position}
                label="Jabatan"
                onChange={(e) => setPosition(e.target.value)}
              >
                {dataPosition.map((item) => (
                  <MenuItem key={item.Id} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12} color="#333333" variant="body2">
              Upload surat keterangan karyawan/magang<Typography component="span" color="error">*</Typography><Typography component="span" fontSize={12} color="error"> (Max. upload 5mb)</Typography>
            </Typography>
            {/* <TextField
              fullWidth
              type="file"
              size="small"
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleUploadChange(event.target.files?.[0] || null)
              }
              inputProps={{
                accept: ".pdf",
              }}
              
            /> */}
            <StyledMuiFileInput
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Choose File"
              InputProps={{
                inputProps: {
                  accept: ".pdf",
                },
                startAdornment: <AttachFileIcon />,
              }}
              clearIconButtonProps={{
                title: "Remove",
                children: <CloseIcon fontSize="small" />,
              }}
              value={uploadFile}
              onChange={handleUploadChange}
            />
            {uploadName && (
              <Typography fontSize={12}>
                  PDF Document:
                <LinkMui href={uploadUrl} underline="none">
                  {" "}
                  {uploadName}
                </LinkMui>
              </Typography>
            )}
          </Grid>
        </>
        )}

        {jobStatus === "2" && (
        <>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="business-sector-select">
                Bidang Usaha
              </InputLabel>
              <Select
                labelId="business-sector-select"
                id="business-sector"
                value={businessSector}
                label="Bidang Usaha"
                onChange={(e) => setBusinessSector(e.target.value)}
              >
                {dataBusinessSector.map((item) => (
                  <MenuItem key={item.Id} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12} color="#333333" variant="body2">
              Upload surat keterangan wiraswasta/profesional<Typography component="span" color="error">*</Typography><Typography component="span" fontSize={12} color="error"> (Max. upload 5mb)</Typography>
            </Typography>
            <StyledMuiFileInput
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Choose File"
              InputProps={{
                inputProps: {
                  accept: ".pdf",
                },
                startAdornment: <AttachFileIcon />,
              }}
              clearIconButtonProps={{
                title: "Remove",
                children: <CloseIcon fontSize="small" />,
              }}
              value={uploadFile}
              onChange={handleUploadChange}
            />
            {uploadName && (
              <Typography fontSize={12}>
                  PDF Document:
                <LinkMui href={uploadUrl} underline="none">
                  {" "}
                  {uploadName}
                </LinkMui>
              </Typography>
            )}
          </Grid>
        </>
        )}
      </>
      ) : (
      <>
        <Grid item xs={6}>
          <Stack spacing={1}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
          </Stack>
        </Grid>
      </>
      )}
      </Grid>
      <Divider />

      <Box>
        <Typography mt={2} fontSize={14} fontWeight={600} variant="h6">
          Social Network
        </Typography>
        <Grid container spacing={2} mt={1} mb={3}>
          {dataProfile ? (
            <>
              <Grid item xs={12} mt={1}>
                <TextField
                  fullWidth
                  size="small"
                  id="facebook"
                  label="Facebook"
                  variant="outlined"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextField
                  fullWidth
                  size="small"
                  id="linkedin"
                  label={
                    <>
                      LinkedIn<Typography component="span" color="error">*</Typography>
                    </>
                  }
                  variant="outlined"
                  value={linkedin}
                  onChange={(e) => handleLinkedinChange(e.target.value)}
                />
                {errorMessage && (
                  <Box
                    sx={{
                      background: "#f18700",
                      padding: "4px",
                      borderRadius: "6px",
                      marginTop: "8px",
                      width: "510px"
                    }}
                  >
                    <Typography
                      sx={{ color: "white" }}
                      fontSize={12}
                      fontWeight="bold"
                    >
                      {errorMessage}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextField
                  fullWidth
                  size="small"
                  id="line"
                  label="Line"
                  variant="outlined"
                  value={line}
                  onChange={(e) => setLine(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextField
                  fullWidth
                  size="small"
                  id="portofolio"
                  label="Portfolio Link"
                  variant="outlined"
                  value={portofolio}
                  onChange={(e) => setPortofolio(e.target.value)}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Stack spacing={1}>
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider />

      <Box>
        <Typography mt={2} fontSize={14} fontWeight={600} variant="h6">
          Skills
        </Typography>

        {dataProfile ?
        listSkill.map((value, index) => (
          <Grid container spacing={1} mt={1} mb={3} key={value.skillId}>
            <Grid item xs>
              <FormControl size="small" fullWidth>
                <InputLabel id={`skill-type-${index}-select`}>Type</InputLabel>
                <Select
                  labelId={`skill-type-${index}-select`}
                  id={`skill-type-${index}`}
                  value={value.skillTypeId}
                  label="Type"
                  name="skillTypeId"
                  onChange={(e) => handleSkillChange(index, e)}
                >
                  {dataSkillType.map((item) => (
                    <MenuItem key={item.Id} value={item.Id}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs>
              <FormControl size="small" fullWidth>
                <InputLabel id={`skill-value-${index}-select`}>
                  1 Poor - 10 Excellent
                </InputLabel>
                <Select
                  labelId={`skill-value-${index}-select`}
                  id={`skill-value-${index}`}
                  value={value.skillLevel}
                  label="1 Poor - 10 Excellent"
                  name="skillLevel"
                  onChange={(e) => handleSkillChange(index, e)}
                >
                  {[...Array(10)].map((_, number) => (
                    <MenuItem key={`number-${number + 1}`} value={number + 1}>
                      {number + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs>
              <TextField
                fullWidth
                size="small"
                id={`skill-name-${index}`}
                label={
                  <>
                    Skill<Typography component="span" color="error"> (MUST BE UNDER 30 CHARACTERS!)</Typography>
                  </>
                }
                variant="outlined"
                value={value.skillName}
                name="skillName"
                onChange={(e) => handleSkillChange(index, e)}
                inputProps={{ maxLength: 30 }}
                helperText={`${30 - value.skillName.length} characters remaining`}
              />
            </Grid>
            
            <Grid item xs="auto">
              {index >= 1 ? (
                <IconButton
                  onClick={() => {
                    const list = [...listSkill];
                    list.splice(index, 1);
                    setListSkill(list);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleAddFields()}>
                  <AddOutlined />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )) : (
          <Grid container spacing={1} mt={1} mb={3}>
            <Grid item xs={4}>
              <Stack spacing={1}>
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={1}>
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={1}>
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={40} />
              </Stack>
            </Grid>
          </Grid>
        )}
        
      </Box>

      <Stack spacing={2} direction="row" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => Navigate("/enrichment/update-profile")}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleUpdate}
          disabled={!!errorMessage}
        >
          Save
        </Button>
      </Stack>
    </Container>
  );
}
