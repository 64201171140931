import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Link } from "react-router-dom";
import { breadCrumbLink } from "../styles/common/bread-crumb-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useQueryDashboardGet } from "../api/dashboard";
import { DashboardResponse } from "../types/dashboard";

export function Dashboard() {
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  const [dataDashboard, setDataDashboard] = useState<DashboardResponse>();

  const getDashboard = useQueryDashboardGet({
    query: {
      binusianId: userData.binusianId,
    },
    options: {
      enabled: true,
      onSuccess: (res) => {
        setDataDashboard(res);
      },
      onError: () => {},
    },
  });

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Typography fontSize={14} color="#0097DA">
            Dashboard
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Dashboard
      </Typography>

      <Divider />

      <Box display="flex">
        <Box
          mt={1}
          mr={1}
          component="img"
          src="/assets/icon/yellow-info-icon.svg"
        />

        <Typography fontWeight="bold" fontSize={14} gutterBottom mt={2}>
          Enrichment Registration period is open from {dataDashboard?.startDate}{" "}
          until {dataDashboard?.endDate}
        </Typography>
      </Box>

      <Typography variant="h6" fontSize={12} gutterBottom mt={2}>
        Running Period
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select disabled value="1">
              <MenuItem value="1">
                Odd Semester 2024/2025 (2411 and 2412)
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container>
        <Grid display="flex" mt={2} item>
          <Avatar
            sx={{
              width: "120px",
              height: "120px"
            }} />

          <Box ml={2}>
            <Typography
              variant="h6"
              fontSize={18}
              color="#0097da"
              fontWeight="bold"
            >
              {userData.name}
            </Typography>

            <Typography variant="h6" fontSize={14} fontWeight="bold">
              ENRICHMENT TRACK
            </Typography>

            <Typography variant="h6" fontSize={14}>
              {dataDashboard?.trackDesc}
            </Typography>

            <Box mt={1}>
              <Link to="/enrichment/update-profile">
                <Button
                  sx={{ marginRight: "10px" }}
                  color="warning"
                  variant="contained"
                >
                  {dataDashboard?.updateProfile ? "View Profile" : "Update Profile"}
                </Button>
              </Link>

              <Link to="/enrichment/choose-enrichment-track">
                <Button 
                  variant="contained" 
                  color="primary"
                  disabled={!dataDashboard?.registration}
                >
                  {dataDashboard?.registration ? "Register for Enrichment" : "Can't Choose Track"}
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {dataDashboard?.updateProfile === false && (
        <Typography mt={2} color="#DD413C" fontSize={14}>
          *Update your profile before registering for enrichment
        </Typography>
      )}
    </Container>
  );
}
